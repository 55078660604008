import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import BenefitsSection from "../components/sections/benefits-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import CtaSection from "../components/sections/cta-section"
import PageFirewallSetupHeroSection from "../components/sections/page-firewall-setup-hero-section"
import PageFirewallSetupSection from "../components/sections/page-firewall-setup-section"

const FirewallSetupPage = () => (
  <Layout>
    <Seo
      title='IT Security Orange County'
      description='As a managed IT services provider, our priority is to deliver the most flexible and secure IT systems you need to keep you safe.'
    />

    <PageFirewallSetupHeroSection />
    <PageFirewallSetupSection />
    <BenefitsSection />
    <CapabilitiesSection />
    <QuickQuoteSection />
    <CtaSection />

    <div className="bg-white w-full pt-16 pb-20">
      <h2 className='text-2xl md:text-3xl text-blue-900 text-center font-bold mb-8'>Related IT Services</h2>

      <div className='w-11/12 max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-8 mx-auto'>
        <div>
          <Link to="/secure-remote-access">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Secure Remote Access
            </button>
          </Link>
        </div>
        <div>
          <Link to="/managed-it-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Fully Managed IT Solutions
            </button>
          </Link>
        </div>
        <div>
          <Link to="/server-workstation-setup-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Infrastructure Setup and Maintenance
            </button>
          </Link>
        </div>
        <div>
          <Link to="/hosted-exchange-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Hosted Exchange / Microsoft Exchange
            </button>
          </Link>
        </div>
        <div>
          <Link to="/cloud-backup-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Online Cloud Backup
            </button>
          </Link>
        </div>
        <div>
          <Link to="/voip-design-implementation-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              VOIP Design and Implementation
            </button>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default FirewallSetupPage
